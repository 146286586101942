.question-container {
  width: 50%; /* Set the width to 50% of the screen width */
  padding: 20px;
  margin: 50px auto; 
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center !important; 
}

.question-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
  text-align: center;
}

.answer{
display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  width: 100%;
}

.answer-input, .answer-btn {
  width: 80%;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  align-items: center !important; 
}

.answer-input {
  height: 100px; /* Specific height for the textarea */
  resize: none; /* Prevents resizing the textarea */
  border-radius: 8px;
}

.answer-btn {
  text-align: left; 
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #ccc;
  transition: border-color 0.3s, box-shadow 0.3s; 
  align-self: center;
}

.answer-btn:hover {
  border-color: rgba(0, 107, 145, 0.5); 
  box-shadow: 0 0 10px rgba(0, 107, 145, 0.5); 
}

.answer-btn.selected {
  border-color:  #00B7EA;
  background-color: rgba(0, 183, 234, 0.3);
  box-shadow: 0 0 10px rgba(0, 107, 145, 0.5); 
}

h2, p {
  text-align: center; 
}

.check-btn {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #00B7EA;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 107, 145, 0.5);
  width: 20%;
}

.check-btn:hover {
  background-color: #0056b3;
}


.next-btn {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #2fb520;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 107, 145, 0.5);
  width: 20%;

}

.next-btn:hover {
  background-color: #207217;
}

.navStyle {
    background-color: #FFD31F;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: sticky;
    top: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 0 0 50px 50px;
}

.navStyle .flex {
  display: flex;
}

.navStyle .items-center {
  align-items: center;
}

.navStyle .justify-between {
  justify-content: space-between;
}

.navStyle .justify-center {
  justify-content: center;
}

.max-h-20 {
  max-height: 5rem; 
}

.badge-progress-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.level-badge {
  max-height: 5rem; 
  position: relative;
  z-index: 1; 
}

.progressbar_container {
  margin-left: -40px; 
  height: 20px; 
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  width: 200px; 
}

.progressbar_fill {
  height: 100%;
  background-color: #00B7EA; 
  transition: width 0.3s ease-in-out;
}

.logout-btn {
  padding: 8px 16px;
  margin-right: 20px;
  background-color: #00B7EA;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 107, 145, 0.5);
}

.logout-btn:hover {
  background-color: #0056b3;
}



/* Modal */
/* Overlay styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75); /* Black overlay with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content styling */
.modal-content {
  position: relative;
  padding: 40px;
  box-sizing: border-box;
  width: 90%;
  max-width: 500px; 
  background: #fff;
  border-radius: 15px;
  text-align: center;
  font-family: 'Arial', sans-serif; 
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Popup message styling */
.popup-message {
  font-size: 20px;
  color: #333;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Button styling for success and failure */
.modal-button {
   padding: 10px 20px;
  margin-top: 10px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 107, 145, 0.5);
  width: 40%;
}

.success-btn {
  background-color: #48C78E; /* Green color for success */
  color: white;
}

.failure-btn {
  background-color: #D9534F; /* Red color for failure */
  color: white;
}

.generic-modal-btn {
  background-color: #FFD31F; /* Red color for failure */
  color: white;
}

/* Tutorial */
.tutorial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;  
  text-align: center;
}

.image-box {
  width: 70%;  
  height: 50vh; 
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-box img {
  width: 100%;  
  height: 100%; 
  object-fit: contain; 
}


/* text styles */
.headers {
  font-size: 1.875rem; 
  font-weight: 800; 
  color: #1F2937; 
  margin-bottom: 2rem; 
  text-align: center; 
}

/* Story card for dashbaord  */
.story-card {
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.story-image {
  width: 100%; 
  height: 300px; 
  object-fit: cover; 
}

.story-details {
  padding: 10px;  
  width: 100%;
  box-sizing: border-box; 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.story-button-details {
  padding-top: 0%;
  padding-bottom: 10px;  
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.story-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px; 
}

.check-btn-story {
  padding: 10px 40px;
  /* margin-top: 10px; */
  background-color: #00B7EA;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 107, 145, 0.5);
  width: 100%;

}

.check-btn-story:hover {
  background-color: #0056b3;
}

.progress-bar-container {
  width: 100%;
  background-color: #f0f0f0;
  height: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #4CAF50;
  width: 0%; /* Initial width */
  transition: width 0.3s ease-in-out;
}

/* Notification.css */
.notification-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification-container {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  border-radius: 10px;
}

.notification-container.success {
  background: #f0fff4;
  border: 2px solid #38a169;
}

.notification-container.failure {
  background: #fff5f5;
  border: 2px solid #e53e3e;
}

.notification-content {
  display: flex;
  align-items: center;
}

.notification-image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.notification-text {
  font-size: 18px;
  color: #333;
}


.notification-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}

/* Progress Bar Styles */
.custom-progress-bar-wrapper {
  width: 100%;
  min-width: 230px;
  transform: translateX(-20px);
}
